import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import PageMetaData from 'components/PageMetaData';
import {MultiGrid, RequesterViewType} from 'components/MultiGrid';
import {CardActionType} from 'components/MultiGrid';
import {getCookie} from 'utility';

@withRouter
@connect(state => ({user: state.user, dashboard: state.dashboard}), DashboardStore.actionCreators)
export default class Orders extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
        user: UserStore.StateShape,
        dashboard: DashboardStore.StateShape,
    };

    constructor(props) {
        super(props);
        const gridName = 'myOrdersColumns'
        const {dashboard: {myAccountFilter}} = props;
        const cachedViewType = getCookie(`requesterViewType-${gridName}`)
        this.state = {
            gridName,
            columns: [],
            view: 'Grid',
            orders: [],
            loading: true,
            viewType: cachedViewType ? RequesterViewType[cachedViewType] : myAccountFilter.lineItemIds.length > 0 ? RequesterViewType.AllRequesters : RequesterViewType.CurrentUser,
            showAltOptions: false,
        };
    }

    componentDidMount() {
        const {viewType, gridName} = this.state;

        axios
            .get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`)
            .then(x => {
                this.setState({columns: x.data.columns, view: x.data.view});
            });
        this.getData(viewType === RequesterViewType.AllRequesters);
    }

    getData(showAllOrders) {
        const {dashboard: {myAccountFilter}, resetMyAccountFilter} = this.props;
        this.setState({loading: true});
        let request = {
            showAllOrders: showAllOrders,
            lineItemIds: myAccountFilter.lineItemIds,
        };
        axios
            .post(
                '/ShoppingService/api/v1/orders/myOrders', request
            )
            .then(x => {
                if (myAccountFilter.lineItemIds) resetMyAccountFilter();
                let altOptions = x.data.some(a => a.isAltSourcedOption === true);
                this.setState({orders: x.data, showAltOptions: altOptions, loading: false});
            });
    }

    handleSelect(item) {
        const {history} = this.props;
        const {location} = window;
        
        if (item.isServiceItem) {
            history.push(`/service/${item.lineItemId}`, {from: location.pathname + location.search});
        } else if (location.search && location.search.indexOf('altOptions') > -1) {
            history.push(`/order/${item.lineItemId}#${item.lineItemId}`, item);
        } else history.push(`/order/${item.lineItemId}`, item);
    }

    onGridRefresh() {
        const {viewType} = this.state;
        this.getData(viewType === RequesterViewType.AllRequesters);
    }

    handleRequesterToggle(requesterViewType) {
        this.setState({viewType: requesterViewType});
        this.getData(requesterViewType === RequesterViewType.AllRequesters);
    }

    render() {
        const {
            gridName,
            columns,
            orders,
            view,
            loading,
            viewType,
            showAltOptions,
        } = this.state;
        const {
            user: {
                settings: {awaitingPoEnabled, punchoutEnabled, showApprovalTab},
            },
        } = this.props;

        let gridKeys = [
            {
                id: 'awaitingApproval',
                fieldName: 'isApproval',
                value: true,
                tabDisplay: 'Approvals',
                cardAction: CardActionType.AwaitingApprovalReadOnly,
            },
            {
                id: 'submittedForPunchout',
                fieldName: 'isPunchOut',
                value: true,
                tabDisplay: 'Punchout',
                height: '530',
            },
            {
                id: 'awaitingPO',
                fieldName: 'isHoldForPo',
                value: true,
                tabDisplay: 'Awaiting PO',
                height: '540',
            },
            {
                id: 'ordered',
                fieldName: 'statusId',
                value: '7,25',
                tabDisplay: 'Ordered',
                cardAction: CardActionType.OrderHistory,
            },
            {
                id: 'altOptions',
                fieldName: 'isAltSourcedOption',
                value: true,
                tabDisplay: 'Alt. Options',
                cardAction: CardActionType.AltOptions,
            },
            {
                id: 'shipped',
                fieldName: 'inTransit',
                value: true,
                tabDisplay: 'Shipped',
                cardAction: CardActionType.Shipped,
                height: '540',
            },
            {
                id: 'delivered',
                fieldName: 'isDelivered',
                value: true,
                tabDisplay: 'Delivered',
                cardAction: CardActionType.Delivered,
                height: '540',
            },
        ];

        if (!awaitingPoEnabled) _.remove(gridKeys, x => x.id === 'awaitingPO');
        if (!punchoutEnabled)
            _.remove(gridKeys, x => x.id === 'submittedForPunchout');
        if (!showApprovalTab) _.remove(gridKeys, x => x.id === 'awaitingApproval');
        if (!showAltOptions) _.remove(gridKeys, x => x.id === 'altOptions');

        return (
            <div>
                <PageMetaData
                    title="Orders"
                    pageType="other"
                    trackAnalytics={true}
                />
                <MultiGrid
                    onRequesterToggle={::this.handleRequesterToggle}
                    showRequesterToggle={true}
                    requesterViewType={viewType}
                    gridName={gridName}
                    view={view}
                    gridKeys={gridKeys}
                    data={orders}
                    columns={columns}
                    label="My Account"
                    subLabel="My Open Orders"
                    onSelect={::this.handleSelect}
                    loadingData={loading}
                    headerLink={{text: 'View All Orders', url: '/orders/history'}}
                    showShipInfo={true}
                    onRefresh={::this.onGridRefresh}
                />
            </div>
        );
    }
}
